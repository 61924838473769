<template>
  <div id="tradingBonus">
    <div class="banner-container">
      <div
        class="banner-image"
        :style="{
          backgroundImage: 'url(' + require(`@/assets/images/banner/trading_bonus/bg.png`) + ')',
          backgroundSize: 'cover'
        }"
      >
        <BannerItemV1 v-bind="banner" :lang="imgLang" />
      </div>
    </div>
    <div class="content-box">
      <div class="inner">
        <FundAccount :optIn="optIn" @activateSuccess="getBonusInfo" :campaignID="campaignID" />
        <div class="table-box">
          <div class="table-title">
            {{ $t('promotion.depositBonus.bonusHistory') }}
          </div>
          <BasicTable v-bind="bonusTableScheme" />
        </div>
        <div class="table-box">
          <div class="table-title">
            {{ $t('promotion.tradingBonus.tradingRewardHistory') }}
          </div>
          <BasicTable v-bind="tradingTableScheme" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerItemV1 from '@/components/banner/item/v1/BannerItem.vue';
import FundAccount from '@/components/promotion/tradingBonus/FundAccount.vue';
import BasicTable from '@/components/table/Table.vue';
import { apiCampaignInfo } from '@/resource';
import { isSuccess } from '@/util/restApi';
import CredictReceived from '@/components/promotion/depositBonus/CredictReceived';
import { PROMOTION_TYPE } from '@/constants/promotion';

export default {
  components: { FundAccount, BasicTable, CredictReceived, BannerItemV1 },
  name: 'tradingBonus',
  data() {
    return {
      optIn: false,
      bonusTableData: [],
      welcomeRateLimit: 0,
      tradingTableScheme: {
        headers: [
          {
            value: 'closeTime',
            text: this.$t("promotion.tradingBonus.tradingTable.date")
          },
          {
            value: 'mt4Account',
            text: this.$t("promotion.tradingBonus.tradingTable.tradingAccount")
          },
          {
            value: 'currency',
            text: this.$t("promotion.tradingBonus.tradingTable.currency")
          },
          {
            value: 'lot',
            text: this.$t("promotion.tradingBonus.tradingTable.volumeLot")
          },
          {
            value: 'rewardAmount',
            text: this.$t("promotion.tradingBonus.tradingTable.amount")
          }
        ],
        tableData: []
      },
      bonusTableScheme: {
        headers: [
          {
            value: 'createTime',
            text: this.$t("promotion.tradingBonus.bonusTable.depositDate")
          },
          {
            value: 'mt4Account',
            text: this.$t("promotion.tradingBonus.bonusTable.tradingAccount")
          },
          {
            value: 'currency',
            text: this.$t("promotion.tradingBonus.bonusTable.currency")
          },
          {
            value: 'actualAmount',
            text: this.$t("promotion.tradingBonus.bonusTable.amount")
          },
          {
            value: 'bonusAmount',
            text: this.$t("promotion.tradingBonus.bonusTable.BONUS")
          }
        ],
        tableData: []
      },
      banner: null
    };
  },
  computed: {
    imgLang() {
      return this.$store.state.common.lang;
    },
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.TRADING_BONUS) || {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    }
  },
  methods: {
    getBonusInfo() {
      apiCampaignInfo(this.campaignID)
        .then(({ data }) => {
          if (isSuccess(data)) {
            this.optIn = data.data.in;
            this.bonusTableScheme.tableData = data.data.bonusHistory.map(item => {
              return {
                ...item,
                createTime: this.$options.filters.dateYearLast(item.createTime)
              };
            });
            this.tradingTableScheme.tableData = data.data.rewardHistory.map(item => {
              return {
                ...item,
                closeTime: this.$options.filters.dateYearLast(item.closeTime)
              };
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    getBanner() {
      this.banner = this.$config
        .banner(this.regulator, this.GLOBAL_DOMAIN_WEBSITE)
        .filter(el => el.promotionId === this.campaignID)[0];
    }
  },
  mounted() {
    this.getBonusInfo();
    this.getBanner();
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/tradingBonus.scss';
</style>
