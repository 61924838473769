<template>
  <ConditionDialog :visible.sync="dialogVisible" :isSuccess="isOptIn" @closCbDialog="onClose">
    <template #content>
      <div>
        <i18n :path="isOptIn ? 'promotion.tradingBonus.optInDialog' : 'promotion.tradingBonus.optOutDialog'" tag="p">
          <template v-slot:br>
            <br />
          </template>
        </i18n>
        <div class="btn-box">
          <slot name="btn"></slot>
        </div>
      </div>
    </template>
  </ConditionDialog>
</template>

<script>
import ConditionDialog from '@/components/dialog/ConditionDialog.vue';

export default {
  name: 'CallbackDialog',
  components: { ConditionDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    isOptIn: Boolean
  },
  methods: {
    onClose() {
      this.$emit('close', this.isOptIn);
    }
  }
};
</script>
