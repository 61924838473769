<template>
  <div class="descp2">
    <p>{{title}}</p>
    <ol>
      <li v-for="(description, index) in descriptios" :key="description">
        {{ `${index+1}. ${description}` }}
      </li>
    </ol>
  </div>
</template>

<script>

export default {
  name: 'description',
  props: { 
    title: String,
    descriptios: {
      type: Array,
      default: () => []
    }
  },
}
</script>

