<template>
  <div class="table-wrapper">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="table-box">
          <el-table :data="displayData">
            <el-table-column v-for="header in headers" :key="header.value" :label="header.text" :prop="header.value">
            </el-table-column>
          </el-table>
        </div>
        <Pagination
          :table-data="tableData"
          :display-data.sync="displayData"
          :customizedPageSize="pageSize"
        ></Pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';

export default {
  name: 'Table',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Pagination },
  data() {
    return {
      displayData: this.tableData.slice(0, 10),
      pageSize: 10
    };
  },
  computed: {},
  methods: {}
};
</script>
