<template>
  <GeneralDialog :visible.sync="showDialog" @close="onClose">
    <el-form :model="formData" :rules="rules" ref="form" class="content-wrapper">
      <div class="acount-wrapper">
        <p>{{ $t('promotion.tradingBonus.acountDialogTitle') }}</p>
      </div>
      <div class="select-wrapper">
        <label>{{ `${$t('common.field.accNum')}*` }}</label>
        <el-form-item prop="accountMt4Dto">
          <el-select v-model="formData.accountMt4Dto" :placeholder="$t('common.field.select')" value-key="mt4Account">
            <el-option
              v-for="(accountOption, index) in accountOptions"
              :key="index"
              :value="accountOption.value"
              :label="accountOption.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="btn-wrapper">
        <el-button class="btn-red" @click="onSubmit">
          {{ $t('common.button.submit') }}
        </el-button>
      </div>
    </el-form>
  </GeneralDialog>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';
import { apiOptInWithAccount } from '@/resource';

export default {
  name: 'AcountDialog',
  components: { GeneralDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    campaignID: {
      type: Number,
      required: true
    },
    accountOptions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      rules: {
        accountMt4Dto: [{ required: true, message: this.$t('common.formValidation.accReq'), trigger: 'change' }]
      },
      formData: {
        campaignId: this.campaignID,
        accountMt4Dto: null
      },
      showDialog: false
    };
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue;
      }
    }
  },
  methods: {
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    onClose() {
      this.$emit('close');
    },
    excuteValidate() {
      return new Promise(resolve => {
        this.$refs.form.validate(valid => {
          resolve(valid);
        });
      });
    },
    async onSubmit() {
      try {
        const isValid = await this.excuteValidate();
        if (!isValid) return;

        const { data } = await apiOptInWithAccount(this.formData);
        const isError = data.data === false;
        if (isError) {
          this.showError();
          return;
        }
        this.$emit('activateSuccess');
        this.$emit('close');
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/accountDialog.scss';
</style>
