<template>
  <div class="meta-box">
    <h3>{{ $t('promotion.tradingBonus.banner1') }}</h3>
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div class="descp3">
          {{ optIn ? $t('promotion.tradingBonus.optOutDescription') : $t('promotion.tradingBonus.optInDescription') }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button v-if="optIn" class="el-button btn-blue long-button" data-testid="opt" @click="onSignOut">
          {{ $t('promotion.tradingBonus.signOut') }}
        </el-button>
        <el-button v-else class="el-button btn-blue long-button" data-testid="opt" @click="onActivateCampaign">
          {{ $t('promotion.tradingBonus.activate') }}
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="sub-box2">
      <el-col :xs="24">
        <Description v-bind:title="claimTitle" v-bind:descriptios="claimDescriptions" />
      </el-col>
      <el-col :xs="24">
        <Description v-bind:title="eligibilityTitle" v-bind:descriptios="eligibilityDescriptions" />
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button class="el-button btn-blue long-button" data-testid="fund" @click="goToFund" :disabled="!optIn">
          {{ $t('promotion.tradingBonus.fund') }}
        </el-button>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.tradingBonus.tnc">
          <template v-slot:link>
            <a :href="tncLink" target="_blank" data-testid="tnc">
              {{ $t('promotion.tradingBonus.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>
    <AccountDialog
      :dialogVisible.sync="showAccountDialog"
      @close="onCloseAccountDialog"
      :campaignID="campaignID"
      :accountOptions="accountOptions"
      @activateSuccess="onActivateSuccess"
    />
    <CallbackDialog :dialogVisible.sync="showResultDialog" :isOptIn="isOptIn" @close="onCloseResultDialog" />
  </div>
</template>

<script>
import { apiCampaignOpt, apiGetRedeemableAccounts } from '@/resource';
import blackList from '@/mixins/blackList';
import Description from '@/components/description/Description.vue';
import CallbackDialog from '@/components/promotion/tradingBonus/CallbackDialog.vue';
import AccountDialog from '@/components/promotion/tradingBonus/AccountDialog.vue';

export default {
  name: 'fundAccount',
  components: {
    CallbackDialog,
    Description,
    AccountDialog
  },
  props: { optIn: Boolean, campaignID: Number },
  mixins: [blackList],
  data() {
    return {
      showAccountDialog: false,
      form: {
        checked: true
      },
      showResultDialog: false,
      claimTitle: this.$t('promotion.tradingBonus.claimTitle'),
      claimDescriptions: this.$t('promotion.tradingBonus.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.tradingBonus.eligibilityTitle'),
      eligibilityDescriptions: this.$t('promotion.tradingBonus.eligibleDescriptions'),
      redeemableAccounts: [],
      isOptIn: false
    };
  },
  computed: {
    accountOptions() {
      return this.redeemableAccounts.map(({ mt4Account, currency, datasourceId }) => ({
        label: mt4Account,
        value: {
          mt4Account,
          currency,
          datasourceId
        }
      }));
    },
    tncLink() {
      if (this.$store.state.common.lang == 'vi') {
        return `https://${this.GLOBAL_DOMAIN_WEBSITEVN}/terms-and-conditions/100-cash-rebate-terms/`;
      } else {
        return `https://${this.GLOBAL_REFERRAL_SITE}/terms-and-conditions/100-cash-rebate-terms/`;
      }
    }
  },
  methods: {
    onCloseAccountDialog() {
      this.showAccountDialog = false;
    },
    onCloseResultDialog(activate = true) {
      this.showResultDialog = false;
      if (!activate) {
        this.$router.push('/home');
        this.refreshEligibleCampaigns();
      }
    },
    goToFund() {
      this.getDepositBlacklist();
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    },
    async onActivateCampaign() {
      try {
        const { data } = await apiGetRedeemableAccounts(this.campaignID);
        this.redeemableAccounts = data.data;
        this.showAccountDialog = true;
      } catch (e) {
        console.log(e);
      }
    },
    onActivateSuccess() {
      this.showResultDialog = true;
      this.isOptIn = true;
      this.refreshEligibleCampaigns();
      this.$emit('activateSuccess');
    },
    refreshEligibleCampaigns() {
      this.$store.dispatch('promotion/actionGetEligibleCampaigns');
    },
    async onSignOut() {
      try {
        const { data } = await apiCampaignOpt(this.campaignID, false);
        if (!data) return this.showError();

        this.isOptIn = false;
        this.showResultDialog = true;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
